<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <v-layout wrap justify-center>
          <v-flex xs12 py-12>
            <v-img
              contain
              height="80px"
              :src="require('../assets/Images/FeaturedPartners.png')"
            ></v-img>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center py-4>
                <span class="popregular cblack" style="font-size:26px">
                  Institutional Partner
                </span>
              </v-flex>
              <v-flex xs12>
                <v-img
                  contain
                  height="150px"
                  :src="require('../assets/Images/ifaw.png')"
                ></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg8 py-12 v-if="icons">
            <OwlCarousel
              :autoplay="true"
              :loop="false"
              :nav="true"
              :dots="false"
              :responsive="owlResponsive"
            >
              <template v-for="(item, i) in icons">
                <v-layout wrap :key="i">
                  <v-flex px-md-1 px-lg-2 px-sm-2 px-3>
                    <v-card flat color="rgba(0,0,0,0)" elevation="0">
                      <v-img contain height="150px" :src="item.name">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#FF6907"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      owlResponsive: {
        0: { items: 2, nav: false },
        600: { items: 3, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 5, nav: false },
        2560: { items: 10, nav: false },
      },
      icons: [
        {
          name: require("../assets/Images/wlt.png"),
        },
        {
          name: require("../assets/Images/tata.png"),
        },
        {
          name: require("../assets/Images/japan.png"),
        },
        {
          name: require("../assets/Images/iucn.png"),
        },
        {
          name: require("../assets/Images/hcl.png"),
        },
        {
          name: require("../assets/Images/inl.png"),
        },
        {
          name: require("../assets/Images/oracle.png"),
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center style="margin: -1px">
      <v-flex xs12 text-left>
        <v-img
          class="align-center"
          :contain="$vuetify.breakpoint.name == 'xs' ? false : true"
          :cover="$vuetify.breakpoint.name != 'xs' ? false : true"
          :height="$vuetify.breakpoint.name == 'xs' ? '260px' : 'auto'"
          :src="require('../assets/Images/JobsHeader.jpg')"
        >
        <v-layout wrap justify-center>
          <v-flex xs12 align-self-center text-center pt-lg-14>
            <span :style="$vuetify.breakpoint.name=='xs' || $vuetify.breakpoint.name=='sm'?'font-size:55px':'font-size:75px'" class="oswaldbold cwhite">
              VOLUNTEER
            </span>
          </v-flex>
        </v-layout>
        
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
};
</script>
